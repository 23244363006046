body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiFormControl-root.customClock {
    position: relative;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 7px 10px 10px;
}
.customClock .MuiInput-underline:before,
.customClock .MuiInput-underline:after{
    display:none
  }

  .MuiFormControl-root.customClock label {
    background-color: #fff;
    position: absolute;
    top: -7px;
    left: 7px;
    padding: 0 8px;
}
.MuiFormControl-root.customClock button {
    margin-right: -8px;
}
.MuiFormControl-root.customClock button svg{
    width: 20px;
    fill: #000;
}
.MuiPickersPopper-root .MuiPaper-elevation{
    transform: scale(0.9) !important;
    margin: 5px 0px 0 36px;
    top: 8px;
    position: relative;
}
.customClock label + .MuiInput-formControl{
margin-top: 5px;
}
.custom-clock-input input {
    width: 70px;
}
.MuiGrid-item{
  position:relative;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ff8b46;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
  background-color: #ffffff14 !important;
}


#login-form .MuiFormControl-root,
#forgot-pass-form .MuiFormControl-root,
#reset-pass-form .MuiFormControl-root
{
  background-color: #eff0f2;
}

#login-form .MuiFormControl-root label, 
#forgot-pass-form .MuiFormControl-root label,
#reset-pass-form .MuiFormControl-root label
{
  background-color: #f6931c;
  padding: 3px 10px 3px 10px;
}

#login-form .MuiFormControl-root fieldset,
#forgot-pass-form .MuiFormControl-root fieldset,
#reset-pass-form .MuiFormControl-root fieldset
{
  border: none;
}

th , th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #ffffff;
}
  

  
