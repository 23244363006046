html{
    padding: 0;
    margin: 0;
    height: 100%;
}

body{
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #eaecfb;
    color: #18264d;
    height: 100%;
}
#root{
    height: 100%;
}

*{
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a{
    color: inherit;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}
p{
    margin-top: 0;
}

$sizes:12;
@mixin margin-classes{
    @for $var from 0 to $sizes {
        $margin: $var * 0.25rem;
        .m-#{$var} {margin: $margin;}
        .ml-#{$var} {margin-left: $margin;}
        .mr-#{$var} {margin-right: $margin;}
        .mt-#{$var} {margin-top: $margin;}
        .mb-#{$var} {margin-bottom: $margin;}
    }
}

@include margin-classes;

@mixin padding-classes{
    @for $var from 0 to $sizes {
        $padding: $var * 0.25rem;
        .p-#{$var} {margin: $padding;}
        .pl-#{$var} {margin-left: $padding;}
        .pr-#{$var} {margin-right: $padding;}
        .pt-#{$var} {margin-top: $padding;}
        .pb-#{$var} {margin-bottom: $padding;}
    }
}

@include padding-classes;

.btn{
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 16px;
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0,0.14), 0px 1px 5px 0px rgba(0, 0, 0,0.12);
}

.btn-primary{
    background: #184a7b;
    color: #fff;
}

.btn-secondary{
    background: #ff8b46;
    color: #fff;
}

.btn-sm{
    max-width: 80px;
    margin-left: auto;
}

.MuiInputLabel-asterisk{
    color: red;
}

// ------------------------

.compliance-container{
    display: flex;
    flex-wrap: wrap;
}

.btn-send-reminder{
    width:120px !important;
    padding :4px 4px !important;
}

.rate-symbol{
    width: 100%;
    position: relative;
    padding-left: 40px;
    .symbol{
        position: absolute;
        font-weight: 500;
        font-size: 23px;
        height: 100%;
        width: 38px;
        background: #e6e6e6;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
}

.box-container{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px 24px;
    .box-list{
        flex: 0 0 33.33%;
        padding: 0 16px;
        .inner-box-list{
            background: #184a7b;
            padding: 20px;
            min-height: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 6px;
            h2{
                margin: 0;
                color: #fff;
            }
            p{
                margin-bottom: 0;
                color: #fff;
            }
            svg{
                fill: #fff;
                width: 40px;
                height: 40px;
            }
        }
    }
}

.sub-menu{
    height: 0;
    overflow: auto;
    transition: all 0.2s ease-in-out;
    &.active{
        height: auto;

    }
}

.sub-menu-icon{
    transition: all 0.2s ease-in-out;
    &.active{
        transform: rotateZ(180deg);

    }
}

.pac-container {
    z-index: 9999;
}