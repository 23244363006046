
.hideSidebar{
    width: 80px;
}
.hideSidebar .one{
    display: none;
}
.hideSidebar .two{
    transform: rotate(45deg) translate(6px, 6px);
}
.hideSidebar .three{
    transform: rotate(-45deg);
}

.hideSidebar ul {
    width: auto;
}
.hideSidebar ul .menuName{
    display: none;
}
.hideSidebar ul .activeIcon{
    min-width: 30px;
}
.hideSidebar .logo img{
    width: 60px;
    margin: 15px 0 0 0;
}
.hideSidebar .sidebarSubMenu .MuiListItemText-root{
    display: none;
}


.hideSidebar .csslogo{
    max-width: 50px;
}

.custom_fullwindow{
    margin-left: 70px !important;
}